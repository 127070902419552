<template>
  <div>
    <slot />
    <v-layout>
      <v-bottom-navigation bgColor="primary" :elevation="1" grow>
        <v-btn
          v-for="item in navigation"
          :key="item.name"
          @click="navigateTo(item.path)"
          :value="item.name"
        >
          <v-icon>{{ item.icon }}</v-icon>
          {{ item.name }}
        </v-btn>
      </v-bottom-navigation>
    </v-layout>
  </div>
</template>

<script setup lang="ts">
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/vue';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import { useAuth } from '~~/composables/useAuth';
import { useUserStore } from '~~/stores/user';
import { UserInfo } from '~~/types';
import { storeToRefs } from 'pinia';

const userStore = useUserStore();
const { user } = storeToRefs(userStore);

const navigationOptions = [
  {
    name: 'Profile',
    href: '#',
    path: '/profile',
    current: false,
    icon: 'mdi-history',
  },
  {
    name: 'Reviews',
    href: '#',
    path: '/reviews',
    current: false,
    icon: 'mdi-star',
  },
  {
    name: 'Admin Reports',
    href: '#',
    path: '/admin/reports',
    current: false,
    icon: 'mdi-history',
    hidden: () => user.value?.role !== 'admin',
  },
];

const navigation = navigationOptions.filter((item) => !!!item.hidden || !item.hidden());

onMounted(() => {
  navigation.forEach((item) => {
    if (item.path === location.pathname) {
      item.current = true;
    }
  });
});

const shareLink = () => {
  const { shareLink } = useShare();
  shareLink();
};

const logOut = () => {
  const { signOutUser } = useAuth();
  signOutUser();
};
</script>
